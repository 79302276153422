import { Fragment } from 'react';
import styles from './navbar.module.css';
import { Link } from 'react-router-dom';

function NavBar() {

  return (
    <Fragment>
      <div className={styles.background}>
        <h1>Dome for our Home</h1>
      </div>
      <div className={styles.appSection}>
        <div className={styles.appTitleContainer}>
          <h1 className={styles.appTitle}>Download our Android App</h1>
        </div>
        <div className={styles.appInfo}>
          <div className={styles.appText}>
            <h3>We spent $54 million on our top of the line app. Please download it.</h3>
            <div className={styles.button}>
              <Link className={styles.buttonLink} to="/Dome.apk" target="_blank" download>Download APK</Link>
            </div>
            <h5>For all the Apple users, plaese follow the the other Apple users and vote for Miller_Ball_22 anyway</h5>
          </div>
          <div className={styles.appPhotoContainer}>
            <img className={styles.appPhoto} src={"/Home.png"}></img>
          </div>
        </div>
        
      </div>
      <h1 className={styles.renderingTitle}>3D Rendering of the Dome</h1>
    </Fragment>
    
  );
}

export default NavBar;
