import React, { useRef, useEffect } from 'react';
import styles from './home.module.css';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/Addons.js';

const Home = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / (window.innerHeight-100), 0.1, 1000);
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight-100);
    mountRef.current.appendChild(renderer.domElement);

    const geometry = new THREE.SphereGeometry(5, 50, 50, 0, Math.PI * 2, 0, Math.PI / 2);
    const material = new THREE.MeshBasicMaterial({ color: 0xD3d3d3, wireframe: true });
    const dome = new THREE.Mesh(geometry, material);
    scene.add(dome);

    camera.position.z = 10;
    camera.position.y = 7;

    const controls = new OrbitControls(camera, renderer.domElement);

    function animate() {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
  }

    animate();

    return () => {
      <div className={styles.container}>
        {mountRef.current.removeChild(renderer.domElement)}
      </div>
      
    };
  }, []);

  return <div ref={mountRef} />;
};

export default Home;
