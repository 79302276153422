import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './routes/home/home';
import NavBar from './components/navbar/navbar';

function App() {
  return (
    <div className='full-page-container'>
      <NavBar/>
      <Routes>
        <Route index element={<Home/>} />
      </Routes>
    </div>
  );
}

export default App;
